// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-cv-page-js": () => import("./../src/templates/cv-page.js" /* webpackChunkName: "component---src-templates-cv-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-research-page-js": () => import("./../src/templates/research-page.js" /* webpackChunkName: "component---src-templates-research-page-js" */),
  "component---src-templates-teaching-page-js": () => import("./../src/templates/teaching-page.js" /* webpackChunkName: "component---src-templates-teaching-page-js" */)
}

